import { defineChain } from 'viem';

export const polygon = defineChain({
  id: 137,
  name: 'Polygon',
  network: 'matic',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.tokeny.com/polygon/Wghr7G43VbEL8s3vioFZ2UYQyvh9acwE'],
    },
    public: {
      http: ['https://rpc.tokeny.com/polygon/Wghr7G43VbEL8s3vioFZ2UYQyvh9acwE'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'PolygonScan',
      url: 'https://polygonscan.com',
    },
    default: {
      name: 'PolygonScan',
      url: 'https://polygonscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 25770160,
    },
  },
});
