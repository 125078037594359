import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import { setLocale } from '@tokenyico/design-system';

const SUPPORTED_LANGUAGES = process.env.REACT_APP_LANGUAGES
  ? process.env.REACT_APP_LANGUAGES.split(',')
  : ['en'];

export const config = {
  // override detection if only one language is supported
  lng: SUPPORTED_LANGUAGES.length === 1 ? SUPPORTED_LANGUAGES[0] : undefined,

  // have a common namespace used around the full app
  ns: ['translation'],
  defaultNS: 'translation',

  fallbackLng: SUPPORTED_LANGUAGES.length === 1 ? SUPPORTED_LANGUAGES[0] : SUPPORTED_LANGUAGES,
  returnEmptyString: false,
  debug: true,
  whitelist: SUPPORTED_LANGUAGES,
  load: 'languageOnly',
  interpolation: {
    // React already does escaping
    escapeValue: false,

    // format date objects with moment.js
    format: function (value, format) {
      if (value instanceof Date) return moment(value).format(format);
      return value;
    },
  },
  // react i18next special options (optional)
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
    useSuspense: false,
  },
  detection: {
    // order and from where user language should be detected
    order: ['localStorage', 'navigator'],
    // keys or params to lookup language from
    lookupLocalStorage: 'language',
    // cache user language on
    caches: ['localStorage'],
    // languages to not persist (cookie, localStorage)
    excludeCacheFor: ['cimode'],
  },
};

i18next.on('languageChanged', (lng) => {
  const overrideLng = lng === 'en' ? 'en-GB' : lng; // hard override en-GB for en
  moment.locale(overrideLng);
  setLocale(overrideLng);
});

i18next
  // load translation using http -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init(config);

export default i18next;
