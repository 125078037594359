import { defineChain } from 'viem';
export const polygonAmoy = defineChain({
  id: 80002,
  name: 'Polygon Amoy',
  network: 'maticamoy',
  nativeCurrency: {
    decimals: 18,
    name: 'MATIC',
    symbol: 'MATIC',
  },
  rpcUrls: {
    default: { http: ['https://rpc-amoy.polygon.technology'] },
    public: { http: ['https://rpc-amoy.polygon.technology'] },
  },
  blockExplorers: {
    default: { name: 'OK LINK', url: 'https://www.oklink.com/amoy' },
    public: { name: 'OK LINK', url: 'https://www.oklink.com/amoy' },
  },
  testnet: true,
});
