import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';
import './i18n';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';
import { applyPolyfills, defineCustomElements } from '@tokenyico/design-system/loader';
import '@tokenyico/design-system/dist/design-system/design-system.css';
import { LoaderDots as Loader } from '@tokenyico/tokeny-ui';
import theme from 'assets/themes/default/themeConfig.json';
import { createWeb3Modal } from '@web3modal/wagmi/dist/esm/exports/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { walletConnectProvider } from '@web3modal/wagmi';
import {
  mainnet,
  polygonMumbai,
  goerli,
  avalanche,
  avalancheFuji,
  klaytn,
  klaytnBaobab,
  telos,
  telosTestnet,
} from 'viem/chains';
import { polygonAmoy, polygon } from 'app/helpers/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { publicProvider } from 'wagmi/providers/public';

const App = lazy(() => import('./app/App'));

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID; // Project's unique identifier that can be obtained at cloud.walletconnect.com. Enables  functionalities within Web3Modal: wallet and chain logos, optional WalletConnect RPC, support for all wallets from WalletConnect explorer and WalletConnect v2 support.

// export these so we can access them for dfns with ethers.js
export const chainList = [
  mainnet,
  polygon,
  polygonMumbai,
  polygonAmoy,
  goerli,
  avalanche,
  avalancheFuji,
  klaytn,
  klaytnBaobab,
  telos,
  telosTestnet,
];
export const providers = [
  walletConnectProvider({ projectId }),
  publicProvider(),
  infuraProvider({ apiKey: process.env.REACT_APP_INFURA_ID }),
  alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_ID }),
];

const connectorImages = {
  metaMask: 'https://metamask.io/favicon-32x32.png',
};

// Create metadata
const metadata = {
  // TODO: review metadata
  name: process.env.REACT_APP_BRANDED_NAME,
  description: '',
  url: window.location.hostname,
  icons: [`/src/assets/themes/${process.env.REACT_APP_THEME_NAME}/favicon.ico`],
};

const themeVariables = {
  // https://docs.walletconnect.com/web3modal/react/theming
  '--w3m-z-index': 9999,
  '--w3m-border-radius-master': '0.5px',
};
const includeWalletIds = [
  // for more recommended wallet ids :> https://walletconnect.com/explorer
  // 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // MetaMask
  '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927', // Ledger
  '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f', // Safe/Gnosis Safe
  '5864e2ced7c293ed18ac35e0db085c09ed567d67346ccb6f58a0327a75137489', // Fireblocks
];

/**
 * Wagmi Config
 */
const { chains, publicClient } = configureChains(chainList, providers);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      options: { shimDisconnect: true },
    }),
    new WalletConnectConnector({ chains, options: { projectId, metadata, showQrModal: false } }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
  ],
  publicClient,
});

/**
 ** Init: Sentry Error tracking
 **/
const env = process.env.REACT_APP_ENV || process.env.REACT_APP_DEPLOY_ENV;
if (env !== 'development') {
  Sentry.init({
    dsn: 'https://d91a42477ab542798c9829207beac17d@sentry.io/1773244',
    environment: process.env.REACT_APP_DEPLOY_ENV,
    release: `servicing-investor@${process.env.REACT_APP_RELEASE}`,
  });
  Sentry.configureScope((scope) => {
    scope.setTag('token_trader', process.env.REACT_APP_API_URL_TOKEN_TRADER);
    scope.setTag('user_pool', process.env.REACT_APP_AWS_USER_POOL_ID);
    scope.setTag('servicing_api', process.env.REACT_APP_API_URL_SERVICING);
    scope.setTag('release_date', process.env.REACT_APP_BUILD_DATE);
  });
}

require('./index.less');
// require('./index.scss');

/**
 ** Init: @tokenyico/design-system
 **/
applyPolyfills().then(() => {
  defineCustomElements();
});

/**
 ** Init: Client UI theme
 **/
const html = document.getElementsByTagName('html')[0];
html.style.setProperty('--primary', theme.primary);
html.style.setProperty('--primary-darken', theme.primaryDark);

/**
 ** Init: Gooole Tag Manager
 **/
if (theme.gtag) {
  const tagManagerArgs = {
    gtmId: theme.gtag,
  };
  TagManager.initialize(tagManagerArgs);
}

createWeb3Modal({
  themeMode: 'light',
  includeWalletIds,
  featuredWalletIds: includeWalletIds,
  themeVariables,
  wagmiConfig,
  projectId,
  chains,
  connectorImages,
});

/**
 ** Init: React app
 **/

const Loading = () => (
  <div className="loading _flex:center">
    <Loader />
  </div>
);

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
